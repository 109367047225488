import { navigate } from "gatsby"
import React from "react"
import SEO from "../SEO/SEO"
import SampleAccess from "./SampleAccess"

const Books = [
  {
    title: "Multi 1A, Lærernes bok",
    ISBN: 9876543212343,
    subTitle: "Undertittel",
    inStorage: true,
    expectedIn: "",
    allreadySent: false,
    expiredDate: "04.05.21",
    lang: "Norsk, Bokmål",
  },
  {
    title: "Multi 2A",
    ISBN: 9876543212342,
    subTitle: "Undertittel",
    inStorage: true,
    expectedIn: "",
    allreadySent: true,
    expiredDate: "04.05.21",
    lang: "Norsk, Bokmål",
  },
  {
    title: "Multi 5A, Elevbok",
    ISBN: "9876543212341",
    subTitle: "Undertittel",
    inStorage: false,
    expectedIn: "04.05.21",
    allreadySent: false,
    expiredDate: "04.05.21",
    lang: "Norsk, Bokmål",
  },
]

const FormSampleAccess = () => {
  const onSend = () => {
    // TODO: check if assessmentCopy or SampleAccess from url param, navigate to the respective page
    return navigate("/minside/ordre")
  }

  return (
    <>
      {/* Header tag from */}
      {/* Main here */}
      <div className="flex h-screen">
        <SEO title="Prøvetilgang" description="Gyldendal sin hjem side." />
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8 m-auto ">
          <SampleAccess />
        </div>
      </div>
    </>
  )
}

export default FormSampleAccess

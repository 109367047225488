import React, { useEffect, useMemo, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"

const MyOrdersData = ({ data }) => {
  const [material, setMaterial] = useState({
    id: "",
    iSBN: "",
    name: "",
    vareEierStatus: "",
    language: "",
  })
  useEffect(() => {
    const materialId = data.materialId
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterials/" + materialId)
      .then((response) => {
        console.log(response.data)
        setMaterial((prevState) => ({
          ...prevState,
          id: response.data.id,
          iSBN: response.data.iSBN,
          name: response.data.name,
          vareEierStatus: response.data.vareEierStatus,
          language: response.data.language,
        }))
      })
      .catch((error) => {
        console.log("error")
        console.log(error)
      })
      .finally(function () {
        // always executed
      })
  }, [])

  return (
    <li>
      <div className="relative pb-4 border-b space-y-4">
        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex space-x-3 justify-between">
          <div>
            <span className="block font-semibold text-md">{material.name}</span>
            <span className="block text-sm">{material.name}</span>
            <span className="block text-sm pt-4 text-gray">ISBN: {material.iSBN}</span>
          </div>
          <div className="float-right text-right">
            <span className="block text-sm italic">
              {material.language ? "Forventet levering 5-10 virkedager" : "Forventet salgsdato " + material.name}
            </span>
          </div>
        </div>
      </div>
    </li>
  )
}

const MyOrders = () => {
  const [order, setOrder] = useState([])
  const [orderLine, setOrderLine] = useState([])
  useEffect(() => {
    const orderId = window.sessionStorage.getItem("orderId")
    console.log(orderId)
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalOrders/" + orderId)
      .then((response) => {
        console.log(response.data.orderLines)
        setOrder(response.data)
        setOrderLine(response.data.orderLines)
      })
      .catch((error) => {
        console.log("error")
        console.log(error)
      })
      .finally(function () {
        // always executed
      })
  }, [])

  return (
    <div className="bg-white rounded-lg p-4 border-blue">
      <div className="relative lg:pb-2">
        <h2 className="text-2xl font-bold">Mine bestillinger</h2>
        <p className="text-md">Din forespørsel er godkjent. Bekreftelsesemail er sendt til din e-post.</p>
      </div>
      <ul>
        {orderLine.map((data) => (
          <MyOrdersData data={data} />
        ))}
      </ul>
    </div>
  )
}

export default MyOrders

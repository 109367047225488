import React, { useEffect, useState } from "react"
import { fetchApi } from "../Common/WebApi-utils"
import LoadingModal from "../Modals/LoadingModal"

const Books = [
  {
    title: "Multi 1A, Lærernes bok",
    ISBN: 9876543212343,
    subTitle: "Undertittel",
    inStorage: true,
    expectedIn: "",
    allreadySent: false,
    lang: "Norsk, bokmål",
  },
  {
    title: "Multi 2A",
    ISBN: 9876543212342,
    subTitle: "Undertittel",
    inStorage: true,
    expectedIn: "",
    allreadySent: true,
    lang: "Norsk, bokmål",
  },
  {
    title: "Multi 5A, Elevbok",
    ISBN: "9876543212341",
    subTitle: "Undertittel",
    inStorage: false,
    expectedIn: "04.05.21",
    allreadySent: false,
    lang: "Norsk, bokmål",
  },
]

const MyAssessmentCopyData = ({ data }) => {
  const [material, setMaterial] = useState({
    id: "",
    iSBN: "",
    name: "",
    vareEierStatus: "",
    language: "",
  })
  useEffect(() => {
    const materialId = data.materialId
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalMaterials/" + materialId)
      .then((response) => {
        console.log(response.data)
        setMaterial((prevState) => ({
          ...prevState,
          id: response.data.id,
          iSBN: response.data.iSBN,
          name: response.data.name,
          vareEierStatus: response.data.vareEierStatus,
          language: response.data.language,
        }))
      })
      .catch((error) => {
        console.log("error")
        console.log(error)
      })
      .finally(() => {
        //allways executed
      })
  }, [])
  return (
    <li>
      <div className="relative pb-4 border-b space-y-4">
        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
        <div className="relative flex space-x-3 justify-between">
          <div>
            <span className="block font-semibold text-md">{material.name}</span>
            <span className="block text-sm">{material.name}</span>
            <span className="block text-sm pt-4">ISBN: {material.iSBN}</span>
          </div>

          <div className="text-right text-sm">
            <span className="block text-sm text-right">{material.language}</span>
          </div>
        </div>
      </div>
    </li>
  )
}

const MyAssessmentCopy = () => {
  const [loading, setLoading] = useState(false)
  const [material, setMaterial] = useState([])

  useEffect(() => {
    setLoading(true)
    fetchApi(process.env.GATSBY_API_URL_APIM + "/GyldendalOrderLines/me")
      .then((response) => {
        console.log(response.data)
        setMaterial(response.data)
      })
      .catch((error) => {
        console.log("error")
        console.log(error)
      })
      .finally(function () {
        {
          setLoading(false)
        }
      })
  }, [])

  return (
    <div className="bg-white rounded-lg p-4 border-blue">
      {loading ? <LoadingModal /> : null}
      <div className="relative lg:pb-2">
        <h2 className="text-2xl font-bold">Mine vurderingseksemplarer</h2>
      </div>
      <ul>
        {material.map((data) => (
          <MyAssessmentCopyData data={data} />
        ))}
      </ul>
    </div>
  )
}

export default MyAssessmentCopy
